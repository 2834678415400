
import { defineComponent, ref } from 'vue'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import { Input } from '@progress/kendo-vue-inputs'
import '@progress/kendo-ui/js/kendo.dropdownlist'

export default defineComponent({
  components: {
    FeildInputGroup,
    KendoInput: Input
  },
  setup() {
    // 主標
    const title = ref('您的 IP 已被封鎖')
    const tel = ref('0800-123-3210')
    const contents = ref(
      '您好，我們是 PIXIS 網管中心，我們發現您已違反我們的網路使用條例，請確認以下違規原因以及裝置設備，如有問題可聯繫我們已解決相關問題。'
    )
    // 提交表單
    const submitForm = () => {
      // FIXME: 做後續處理
    }
    return {
      title,
      tel,
      contents,
      submitForm
    }
  }
})
