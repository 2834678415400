<template>
  <div id="view-setting">
    <Topbar :backTo="{ name: 'Site' }">Taipei Office 區域設定</Topbar>
    <div class="content-container">
      <SideNav>
        <router-link
          class="side-nav-link"
          :to="{ name: 'EditGeneral', params: $route.params.id }"
          >屬性設定</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'GroupSetting', params: $route.params.id }"
          >網段群組設定</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'DHCPList', params: $route.params.id }"
          >註冊的 IP 派發來源</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'EditBlockMessage', params: $route.params.id }"
          >封鎖訊息 &amp; 驗證方式</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'ADAutoRegisterSetting', params: $route.params.id }"
          >AD 自動授權設定</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'RadiusSiteConfigSetting', params: $route.params.id }"
          >802.1x 設定</router-link
        >
      </SideNav>
      <div class="setting-container">
        <!-- 標題 -->
        <div class="title-row border-0">
          <h3 style="font-size: 22px;">封鎖訊息 &amp; 驗證方式</h3>
        </div>
        <div class="d-flex">
          <!-- 啟用開關 -->
          <div style="max-width: 255px; min-width: 255px; margin-right: 44px;">
            <div class="feild-row" style="border-top: 1px solid #393D46;">
              <p class="feild-name" style="min-width: 194px;">啟用封鎖訊息</p>
              <p class="feild-value">
                <KendoSwitch :checked="true"></KendoSwitch>
              </p>
            </div>
            <div class="feild-row" style="border-bottom: 1px solid #393D46;">
              <p class="feild-name" style="min-width: 194px;">啟用驗證方式</p>
              <p class="feild-value">
                <KendoSwitch :checked="true"></KendoSwitch>
              </p>
            </div>
          </div>
          <div class="flex-grow-1" style="min-width: 500px;">
            <!-- 上：封鎖訊息內容 -->
            <div
              class="title-row align-items-center"
              style="border-color: #393D46;"
            >
              <h4 class="my-0" style="font-size: 18px;">
                封鎖訊息內容
                <a href="#" @click.prevent="togglePreviewBlock"
                  ><SvgIcon icon="eye" class="ml-2"
                /></a>
              </h4>
              <button
                class="btn form-btn-outline mr-2"
                @click="toggleEditBlockMessage"
              >
                編輯
              </button>
            </div>
            <div style="background-color: #1B1E23;">
              <div class="feild-row">
                <p
                  class="feild-name pl-4"
                  style="max-width: 180px; min-width: 180px;"
                >
                  主標題
                </p>
                <p class="feild-value">您的 IP 已被封鎖</p>
              </div>
              <div class="feild-row">
                <p
                  class="feild-name pl-4"
                  style="max-width: 180px; min-width: 180px;"
                >
                  內容
                </p>
                <p class="feild-value pr-5">
                  您好，我們是 PIXIS
                  網管中心，我們發現您已違反我們的網路使用條例，請確認以下違規原因以及裝置設備，如有問題可聯繫我們已解決相關問題。
                </p>
              </div>
              <div class="feild-row border-0">
                <p
                  class="feild-name pl-4"
                  style="max-width: 180px; min-width: 180px;"
                >
                  聯繫相關人員
                </p>
                <p class="feild-value">0800-123-3210</p>
              </div>
            </div>
            <!-- 下：驗證方式 -->
            <div
              class="title-row align-items-center mt-5"
              style="border-color: #393D46;"
            >
              <h4 class="my-0" style="font-size: 18px;">驗證方式</h4>
              <button
                class="btn form-btn-outline mr-2"
                @click="toggleEditVerify"
              >
                編輯
              </button>
            </div>
            <div style="background-color: #1B1E23;">
              <div class="feild-row">
                <p
                  class="feild-name pl-4"
                  style="max-width: 180px; min-width: 180px;"
                >
                  驗證方式
                </p>
                <p class="feild-value">AD 驗證</p>
              </div>
              <div class="feild-row">
                <p
                  class="feild-name pl-4"
                  style="max-width: 180px; min-width: 180px;"
                >
                  內容
                </p>
                <p class="feild-value">
                  啟用兩階段驗證
                  <SvgIcon icon="warning" />
                </p>
              </div>
              <div class="feild-row">
                <p
                  class="feild-name pl-4"
                  style="max-width: 180px; min-width: 180px;"
                >
                  聯繫相關人員
                </p>
                <p class="feild-value pr-5">
                  員工登入<br />訪客登入，預設時間 4 小時，且訪客可自訂授權時間
                </p>
              </div>
              <div class="feild-row">
                <p
                  class="feild-name pl-4"
                  style="max-width: 180px; min-width: 180px;"
                >
                  驗證標題
                </p>
                <p class="feild-value">
                  PIXIS 驗證
                </p>
              </div>
              <div class="feild-row border-0 mb-5">
                <p
                  class="feild-name pl-4"
                  style="max-width: 180px; min-width: 180px;"
                >
                  驗證帳號文字顯示
                </p>
                <p class="feild-value">
                  使用者帳號
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 隱藏：Dialog 容器 -->
  <div class="dialog-container">
    <Dialog
      v-if="showEditBlockMessage"
      :title="'編輯封鎖訊息內容'"
      :width="930"
      :height="560"
      @close="toggleEditBlockMessage"
    >
      <EditBlockMessageForm @close="toggleEditBlockMessage" />
    </Dialog>
    <Dialog
      v-if="showEditVerify"
      :title="'編輯驗證方式'"
      :width="930"
      :height="720"
      @close="toggleEditVerify"
    >
      <EditVerifyForm @close="toggleEditVerify" />
    </Dialog>
    <Dialog
      v-if="showPreviewBlock"
      class="preview-block"
      :title="'preview'"
      :width="490"
      :height="662"
      @close="togglePreviewBlock"
    >
      <PreviewBlockMsg />
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Topbar from '@/components/Topbar/Topbar.vue'
import SideNav from '@/components/SideNav/SideNav.vue'
import '@progress/kendo-ui/js/kendo.switch'
import { KendoSwitch } from '@progress/kendo-inputs-vue-wrapper'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import EditBlockMessageForm from '@/components/Form/EditBlockMessageView/EditBlockMessageForm.vue'
import EditVerifyForm from '@/components/Form/EditBlockMessageView/EditVerifyForm.vue'
import PreviewBlockMsg from './PreviewBlockMsg.vue'

export default defineComponent({
  components: {
    Topbar,
    SideNav,
    KendoSwitch,
    Dialog,
    EditBlockMessageForm,
    EditVerifyForm,
    PreviewBlockMsg
  },
  setup() {
    // Dialog 相關
    const {
      show: showEditBlockMessage,
      toggle: toggleEditBlockMessage
    } = useToggle()
    const { show: showEditVerify, toggle: toggleEditVerify } = useToggle()
    const { show: showPreviewBlock, toggle: togglePreviewBlock } = useToggle()
    return {
      showEditBlockMessage,
      toggleEditBlockMessage,
      showEditVerify,
      toggleEditVerify,
      showPreviewBlock,
      togglePreviewBlock
    }
  }
})
</script>

<style scoped></style>
