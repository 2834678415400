<template>
  <div class="form-wrapper">
    <form>
      <div class="single-feild-group">
        <label class="feild-name">驗證標題</label>
        <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
          <KendoInput
            :type="'text'"
            style="width: 400px;"
            v-model="verify.title"
          />
        </FeildInputGroup>
      </div>
      <div class="single-feild-group">
        <label class="feild-name">驗證帳號文字顯示</label>
        <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
          <KendoInput
            :type="'text'"
            style="width: 400px;"
            v-model="verify.name"
          />
        </FeildInputGroup>
      </div>
      <div class="single-feild-group">
        <label class="feild-name">驗證方式</label>
        <div class="mb-2">
          <BaseCheckbox class="form-checkbox mr-3" v-model="verify.ad"
            >AD 驗證</BaseCheckbox
          >
          <BaseCheckbox class="form-checkbox mr-3" v-model="verify.bd"
            >BD 驗證</BaseCheckbox
          >
          <BaseCheckbox
            class="form-checkbox"
            v-model="verify.ldap"
            :disabled="true"
            >LDAP 驗證</BaseCheckbox
          >
          <div class="text-info" style="padding-left: 250px;">
            <SvgIcon icon="warning" />需啟用 [LDAP 驗證]
          </div>
        </div>
      </div>
      <div class="single-feild-group">
        <label class="feild-name">進階驗證</label>
        <div>
          <div class="mb-2">
            <BaseRadio
              class="form-radio"
              name="advance"
              :value="'none'"
              v-model="verify.advance"
            >
              無
            </BaseRadio>
          </div>
          <div class="mb-2">
            <BaseRadio
              class="form-radio"
              name="advance"
              :value="'two-factor'"
              v-model="verify.advance"
            >
              啟用兩階段驗證
            </BaseRadio>
          </div>
          <div class="mb-2">
            <BaseRadio
              class="form-radio"
              name="advance"
              :value="'two-factor'"
              v-model="verify.advance"
            >
              啟用週期性授權
              <KendoInput
                :type="'text'"
                style="width: 47px; marginLeft: 13px; marginRight: 13px;"
                v-model="verify.routineDays"
              />
              天
            </BaseRadio>
          </div>
        </div>
      </div>
      <div class="single-feild-group">
        <label class="feild-name">登入方式</label>
        <div>
          <div class="mb-2">
            <BaseCheckbox class="form-checkbox" v-model="verify.enableEmployee">
              啟用員工登入
            </BaseCheckbox>
          </div>
          <div class="mb-2">
            <BaseCheckbox class="form-checkbox" v-model="verify.enableVisiter">
              啟用訪客登入， 預設時間
              <DropDownList
                style="width: 95px; margin-left: 13px; marginRight: 13px;"
                :data-source="[
                  { name: '30', value: 30 },
                  { name: '20', value: 20 }
                ]"
                :data-text-field="'name'"
                :data-value-field="'value'"
              />
              天
            </BaseCheckbox>
          </div>
        </div>
      </div>
      <div class="footer-group">
        <button
          type="submit"
          class="btn btn-primary form-btn mr-2"
          @click.prevent="submitForm"
        >
          完成編輯
        </button>
        <button class="btn form-btn-outline" @click.prevent="$emit('close')">
          取消
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import { Input } from '@progress/kendo-vue-inputs'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import BaseCheckbox from '@/components/Checkbox/BaseCheckbox.vue'
import BaseRadio from '@/components/Radio/BaseRadio.vue'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'

export default defineComponent({
  components: {
    FeildInputGroup,
    KendoInput: Input,
    BaseCheckbox,
    BaseRadio,
    DropDownList
  },
  setup() {
    const verify = reactive({
      title: 'PIXIS 驗證',
      name: '使用者帳號',
      ad: true,
      bd: false,
      ldap: false,
      advance: 'two-factor',
      routineDays: 30,
      enableEmployee: true,
      enableVisiter: true
    })

    // 提交表單
    const submitForm = () => {
      // FIXME: 做後續處理
    }
    return {
      verify,
      submitForm
    }
  }
})
</script>

<style scoped></style>
