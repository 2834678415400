
import { defineComponent, reactive } from 'vue'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import { Input } from '@progress/kendo-vue-inputs'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import BaseCheckbox from '@/components/Checkbox/BaseCheckbox.vue'
import BaseRadio from '@/components/Radio/BaseRadio.vue'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'

export default defineComponent({
  components: {
    FeildInputGroup,
    KendoInput: Input,
    BaseCheckbox,
    BaseRadio,
    DropDownList
  },
  setup() {
    const verify = reactive({
      title: 'PIXIS 驗證',
      name: '使用者帳號',
      ad: true,
      bd: false,
      ldap: false,
      advance: 'two-factor',
      routineDays: 30,
      enableEmployee: true,
      enableVisiter: true
    })

    // 提交表單
    const submitForm = () => {
      // FIXME: 做後續處理
    }
    return {
      verify,
      submitForm
    }
  }
})
