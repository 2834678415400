
import { defineComponent } from 'vue'
import Topbar from '@/components/Topbar/Topbar.vue'
import SideNav from '@/components/SideNav/SideNav.vue'
import '@progress/kendo-ui/js/kendo.switch'
import { KendoSwitch } from '@progress/kendo-inputs-vue-wrapper'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import EditBlockMessageForm from '@/components/Form/EditBlockMessageView/EditBlockMessageForm.vue'
import EditVerifyForm from '@/components/Form/EditBlockMessageView/EditVerifyForm.vue'
import PreviewBlockMsg from './PreviewBlockMsg.vue'

export default defineComponent({
  components: {
    Topbar,
    SideNav,
    KendoSwitch,
    Dialog,
    EditBlockMessageForm,
    EditVerifyForm,
    PreviewBlockMsg
  },
  setup() {
    // Dialog 相關
    const {
      show: showEditBlockMessage,
      toggle: toggleEditBlockMessage
    } = useToggle()
    const { show: showEditVerify, toggle: toggleEditVerify } = useToggle()
    const { show: showPreviewBlock, toggle: togglePreviewBlock } = useToggle()
    return {
      showEditBlockMessage,
      toggleEditBlockMessage,
      showEditVerify,
      toggleEditVerify,
      showPreviewBlock,
      togglePreviewBlock
    }
  }
})
