<template>
  <img src="/static/block_lock.svg" alt="" />
  <h1>您的 IP 已被封鎖</h1>
  <p class="discribe">
    您好，我們是 PIXIS
    網管中心，我們發現您已違反我們的網路使用條例，請確認以下違規原因以及裝置設備，如有問題可聯繫我們已解決相關問題。
  </p>
  <div class="detail_content">
    <div class="detail_section">
      <div class="left">
        <svg
          id="Group_6054"
          data-name="Group 6054"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            id="Path_576"
            data-name="Path 576"
            d="M11.193,4a7.189,7.189,0,1,0,7.188,7.189A7.189,7.189,0,0,0,11.193,4ZM12,16.792H10.4v-1.6H12ZM12,13.6H10.4V5.61H12Z"
            transform="translate(0.801 0.801)"
            fill="#171a1d"
          />
          <rect
            id="Rectangle_408"
            data-name="Rectangle 408"
            width="24"
            height="24"
            fill="none"
          />
        </svg>

        <p>原因</p>
      </div>
      <div class="value">
        <span>違規變更 IP</span>
      </div>
    </div>
    <div class="detail_section">
      <div class="left">
        <svg
          id="Group_5002"
          data-name="Group 5002"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <g
            id="Group_5001"
            data-name="Group 5001"
            transform="translate(6 1.938)"
          >
            <path
              id="Path_555"
              data-name="Path 555"
              d="M16.344,6.076A4.138,4.138,0,1,1,15.132,3.15,4.137,4.137,0,0,1,16.344,6.076Z"
              transform="translate(-6 -1.938)"
            />
            <path
              id="Path_556"
              data-name="Path 556"
              d="M11.517,6.076H12.9V18.488a.69.69,0,1,1-1.379,0Z"
              transform="translate(-6 -1.938)"
            />
            <path
              id="Path_557"
              data-name="Path 557"
              d="M10.122,17.241a.69.69,0,0,1-.528.819,5.664,5.664,0,0,0-1.774.651c-.416.265-.441.443-.441.467s.007.118.2.29A3.153,3.153,0,0,0,8.546,20a10.882,10.882,0,0,0,3.659.562A10.888,10.888,0,0,0,15.865,20a3.2,3.2,0,0,0,.965-.528c.194-.172.2-.272.2-.29s-.025-.2-.441-.467a5.606,5.606,0,0,0-1.774-.651.69.69,0,0,1,.28-1.351l.014,0a6.992,6.992,0,0,1,2.22.836,2.013,2.013,0,0,1,1.081,1.63,1.775,1.775,0,0,1-.666,1.322,4.443,4.443,0,0,1-1.4.786,12.183,12.183,0,0,1-4.143.651,12.231,12.231,0,0,1-4.143-.65,4.488,4.488,0,0,1-1.4-.787A1.782,1.782,0,0,1,6,19.178a2.015,2.015,0,0,1,1.08-1.63A6.946,6.946,0,0,1,9.3,16.712a.691.691,0,0,1,.82.528Z"
              transform="translate(-6 -1.938)"
              fill-rule="evenodd"
            />
          </g>
          <rect
            id="Rectangle_418"
            data-name="Rectangle 418"
            width="24"
            height="24"
            fill="none"
          />
        </svg>
        <p>IPv4</p>
      </div>

      <div class="value">
        <span>192.168.15.123</span>
      </div>
    </div>
    <div class="detail_section">
      <div class="left">
        <svg
          id="Group_5003"
          data-name="Group 5003"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            id="Icon_material-desktop-mac"
            data-name="Icon material-desktop-mac"
            d="M452.1-91.951H435.9a1.805,1.805,0,0,0-1.8,1.8v10.8a1.806,1.806,0,0,0,1.8,1.8h6.3l-1.8,2.7v.9h7.2v-.9l-1.8-2.7h6.3a1.806,1.806,0,0,0,1.8-1.8v-10.8A1.805,1.805,0,0,0,452.1-91.951Zm0,10.8H435.9v-9h16.2Z"
            transform="translate(-432 95.951)"
          />
          <rect
            id="Rectangle_419"
            data-name="Rectangle 419"
            width="24"
            height="24"
            fill="none"
          />
        </svg>
        <p>MAC</p>
      </div>

      <div class="value">
        <span>005056A55555</span>
      </div>
    </div>
  </div>
  <div class="more_info">
    <span>聯繫相關人員</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
